import React from "react"

import Input from "./Input"
import Button from "../Button"

const SubscribeForm = ({
  inputClassName,
  buttonClassName,
  placeholder = "johndoe@mail.com",
  className = "",
  buttonLabel = "Subscribe",
  buttonSize = "lg",
}) => {
  return (
    <>
      <form className={`relative ${className}`}>
        <Input
          type="email"
          label=""
          inputClassName={`!px-6 !py-4 !pr-32 ${inputClassName}`}
          placeholder={placeholder}
          name="email"
        />

        <Button
          type="submit"
          className={`absolute -translate-y-1/2 right-2 top-1/2 ${buttonClassName}`}
          size={buttonSize}
        >
          {buttonLabel}
        </Button>
      </form>
    </>
  )
}
export default SubscribeForm
