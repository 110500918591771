import React from "react"
import {
  Dribbble,
  Facebook,
  Github,
  Linkedin,
  Twitter,
} from "react-bootstrap-icons"
import { StaticImage } from "gatsby-plugin-image"
import { useTimer } from "react-timer-hook"

import PageWrapper from "../components/PageWrapper"

import Section from "../components/Section"
import Container from "../components/Container"
import SectionTitle from "../components/SectionTitle"
import SectionText from "../components/SectionText"
import SubscribeForm from "../components/forms/SubscribeForm"

const ComingSoonPage = () => {
  const time = new Date()
  time.setSeconds(time.getSeconds() + 2332710)

  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: time,
    autoStart: true,
  })

  return (
    <>
      <PageWrapper
        headerConfig={{ menuPosition: "left", className: "bg-white" }}
        footerConfig={{
          hasTop: true,
          hasBottomSocial: true,
          bottomClassName: "bg-primary-25",
        }}
      >
        <Section isHero className="overflow-hidden">
          <Container>
            {/* wrap */}
            <div className="grid grid-cols-1 laptop:grid-cols-12">
              {/* left-part */}
              <div className="pb-20 laptop:col-span-6 laptop:pb-0 laptop:pr-20">
                <div className="flex flex-col gap-9 laptop:gap-14">
                  {/* top-part */}
                  <div className="grid grid-cols-2 gap-8 tablet:grid-cols-4">
                    <div className="flex flex-col items-center justify-center gap-2 px-6 py-3 rounded-2xl bg-neutral-100">
                      <h6 className="font-bold text-display-md text-primary-600">
                        {days}
                      </h6>
                      <p className="text-body-md text-neutral-500">Days</p>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-2 px-6 py-3 rounded-2xl bg-neutral-100">
                      <h6 className="font-bold text-display-md text-primary-600">
                        {hours}
                      </h6>
                      <p className="text-body-md text-neutral-500">Hours</p>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-2 px-6 py-3 rounded-2xl bg-neutral-100">
                      <h6 className="font-bold text-display-md text-primary-600">
                        {minutes}
                      </h6>
                      <p className="text-body-md text-neutral-500">Minutes</p>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-2 px-6 py-3 rounded-2xl bg-neutral-100">
                      <h6 className="font-bold text-display-md text-primary-600">
                        {seconds}
                      </h6>
                      <p className="text-body-md text-neutral-500">Seconds</p>
                    </div>
                  </div>
                  {/* middle-part */}
                  <div className="">
                    <SectionTitle isHero={true}>Coming soon</SectionTitle>
                    <SectionText isHero={true} className="max-w-[444px]">
                      Subcribe to our wishlist. We will contact you as soon as
                      our website ready.
                    </SectionText>
                    <div className="flex flex-col items-start justify-start gap-4">
                      <SubscribeForm className="!w-[95%] relative tablet:!w-[70%] laptop:!w-[75%]" />
                      <p className="text-body-sm text-neutral-500">
                        Your email address will not be published.
                      </p>
                    </div>
                  </div>
                  {/* bottom-part */}
                  <div className="flex gap-5">
                    <a
                      href="https://twitter.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Twitter className="text-neutral-500 text-display-xs" />
                    </a>
                    <a
                      href="https://www.linkedin.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Linkedin className="text-neutral-500 text-display-xs" />
                    </a>
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Facebook className="text-neutral-500 text-display-xs" />
                    </a>
                    <a
                      href="https://github.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Github className="text-neutral-500 text-display-xs" />
                    </a>
                    <a
                      href="https://dribbble.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Dribbble className="text-neutral-500 text-display-xs" />
                    </a>
                  </div>
                </div>
              </div>

              {/* right-part */}
              <div className="relative laptop:col-span-6">
                <div className="flex flex-col items-center justify-center gap-5">
                  <div className="flex items-center justify-center gap-5">
                    <div data-aos="zoom-in">
                      <StaticImage
                        src="../images/coming-soon-1.png"
                        alt=""
                        className=" rounded-2xl"
                      />
                    </div>
                    <div data-aos="zoom-in">
                      <StaticImage
                        src="../images/coming-soon-2.png"
                        alt=""
                        className="rounded-2xl"
                      />
                    </div>
                  </div>
                  <div className="flex items-start gap-5 -mx-16 laptop:mx-auto">
                    <div data-aos="zoom-in">
                      <StaticImage
                        src="../images/coming-soon-3.png"
                        alt=""
                        className=" rounded-2xl"
                      />
                    </div>
                    <div data-aos="zoom-in">
                      <StaticImage
                        src="../images/coming-soon-4.png"
                        alt=""
                        className=" rounded-2xl"
                      />
                    </div>
                    <div data-aos="zoom-in">
                      <StaticImage
                        src="../images/coming-soon-5.png"
                        alt=""
                        className="rounded-2xl"
                      />
                    </div>
                  </div>
                </div>
                <div className="absolute left-1/2  -z-10 bottom-1/2 translate-y-1/2 w-screen h-[100vw] laptop:w-[70vw] laptop:h-[70vw] bg-secondary-50 rounded-full"></div>
              </div>
            </div>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}

export default ComingSoonPage
